import "../stylesheets/recomendations_form.scss"
import { useEffect, useState } from "react";
import { DEFINE_INTERESTS_URL, GET_ALL_INTERESTS_URL, GET_USERS_INTERESTS_URL } from "../constants/api-urls";
import Spinner from "../components/Spinner";

export const RecomendationsForm = () => {
  const [userRecomendations, setUserRecomendations] = useState();
  const [recomendations, setRecomendations] = useState([]);
  const [submitBtnText, setSubmitBtnText] = useState('Comencemos');

  useEffect(()=>{
    fetch(GET_USERS_INTERESTS_URL,{
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    }).then((response) => {
      if (!response.ok) {
        throw new Error("Network error.");
      }
      return response.json();
    })
    .then((data) => {
      setUserRecomendations(data.user_interests);
    })
    .catch((err) => {
      console.log("Connection error: ", err);
    });
  },[]);

  useEffect(()=>{
    fetch(GET_ALL_INTERESTS_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network error.");
      }
      return response.json();
    })
    .then((data) => {
      console.log(data)
      const selections = data.map( recomendation =>{
        recomendation.selected = userRecomendations.includes(recomendation.id);
        return recomendation;
      });
      setRecomendations(selections);
    })
    .catch((err) => {
      console.log("Connection error: ", err);
    });
  }, [userRecomendations]);

  function handleSelection(event) {
    const dataId = event.target.dataset.id;
    const new_recomendations = recomendations.map( recomendation => {
      if (recomendation.id == dataId) recomendation.selected = !recomendation.selected;
      return recomendation;
    });
    setRecomendations(new_recomendations);
  }

  function handleSubmit(event) {
    setSubmitBtnText('Enviando...');
    const interests_ids = recomendations
      .filter( recomendation => {return recomendation.selected == true})
      .map( recomendation => {return recomendation.id});
    const params = {
      interests_ids: interests_ids
    };

    fetch(DEFINE_INTERESTS_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network error.");
      }
      setTimeout(()=>setSubmitBtnText('¡Perfecto!'), 500);
    })
    .catch((err) => {
      console.log("Connection error: ", err);
    });
  }

  return (<>
    <div className="container">
      <h1>¿Qué temas sobre el tratamiento de la obesidad te interesan más?</h1>
      <p>Para poder ofrecerte una experiencia de formación personalizada, te recomendamos que selecciones al menos 3 intereses que sean relevantes para ti. De esta forma, podremos recomendarte elementos de formación que se adapen mejor a tus necesidades y objetivos de aprendizaje.</p>
      <p><strong>¡Explora nuestras opciones y elige los intereses que más te interesen para comenzar a aprender!</strong></p>
    </div>

    <div
      className="container d-flex mt-5"
      style={{    
        flexDirection: 'column',
        gap: '10px',
        maxWidth: '600px',
        margin: '0 auto'
      }}
    >
      {recomendations.length === 0 && <Spinner />}
      {recomendations.map(recomendation => (
        <a
          key={recomendation.id}
          className={`btn d-block w-100 selector ${recomendation.selected && 'selected'}`}
          onClick={handleSelection}
          data-id={recomendation.id}
          style={{        
            padding: '10px',
            textAlign: 'center',
            textDecoration: 'none',        
            border: '1px solid #ccc',
            borderRadius: '5px',
            cursor: 'pointer',        
            margin: '0 auto'
          }}
        >
          {recomendation.name}
        </a>
      ))}
    </div>

    <div className="container">
      <a className="btn btn-main btn-sm w-50 d-flex justify-content-center" style={{ margin: "50px auto" }} onClick={handleSubmit}>{submitBtnText}</a>
    </div>
  </>)
};
