import React, { useState } from "react";
import "../stylesheets/restaurant.scss";
import infografia from "../images/sugerencias/infografia.png";
import Col from "react-bootstrap/Col";
import Like from "@mui/icons-material/Favorite";
import { BASE_URL,BACKEND_URL } from "../constants/api-urls.js";
import SimpleSpinner from "./SimpleSpinner.jsx";

function Result({
  resource_id,
  parent,
  title,
  thumbnail,
  thumbnail_url,
  description,
  duration,
  type,
  subtype,
  isViewed,
  isFavorite,
  removeBookmarkFromList,
}) {
  const [isBookmarked, setIsBookmarked] = useState(isFavorite);
  const [isLoading, setIsLoading] = useState(false);

  function getType() {
    return {
      Entrante: "entrante",
      "Plato principal": "principal",
      Postre: "postre",
    }[type];
  }

  const handleCreateBookmark = async () => {
    setIsLoading(true);
    const current_user_id = JSON.parse(localStorage.getItem("user")).id;
    const url = isBookmarked
      ? `${BASE_URL}bookmarks/removeUserBookmark?user_id=${current_user_id}&resource_id=${resource_id}`
      : `${BASE_URL}bookmarks/addUserBookmark?user_id=${current_user_id}&resource_id=${resource_id}`;
    const method = isBookmarked ? "DELETE" : "POST";

    try {
      const response = await fetch(url, {
        method: method,
        credentials: "include",
      });

      if (response.ok) {
        setIsBookmarked(!isBookmarked);

        if (removeBookmarkFromList && isBookmarked) {
          removeBookmarkFromList(resource_id);
        }
      } else {
        console.error("Post failed");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="card-result mb-10">
      <div className="flex-right">
        <button id={`resource-${resource_id}`} className="heart-btn" onClick={handleCreateBookmark}>
          {isLoading ? (
            <SimpleSpinner />
          ) : (
            <i className="heart heart-icon">
              <Like color={isBookmarked ? "error" : "inherit"} />
            </i>
          )}
        </button>
      </div>
      <a href={`/restaurante/${parent}/plato/${resource_id}`}>
        <div className="pe-12">
          <div className="d-flex row">
          <Col
            sm={2}
            className="col justify-content-around align-items-center mb-3 mb-md-0"
          >
            {
              thumbnail ? (
                <img
                  src={`${BACKEND_URL}${thumbnail}`}
                  className="img-fluid img-result"
                  alt="Thumbnail"
                />
              ) : (
                <img
                  src={`${BACKEND_URL}${thumbnail_url}`}
                  className="img-fluid img-result"
                  alt="Thumbnail"
                />
              )
            }
            <a className="badge badge-entrante text-violet">{subtype}</a>
          </Col>

            <Col sm={10} className="">
              <div className="mb-2 title-tarjeta text-violet">{title}</div>
              <div className="description-tarjeta text-violet">
                {description}
              </div>
              <div className="disclaimer mb-2">
                {resource_id === 59 && (
                  <span>"Este material no pertenece a Novo Nordisk, ni es de uso exclusivo"</span>
                )}
              </div>
              {isViewed && (
                <span className="badge-success ms-3">✓ Accedido</span>
              )}
              <div className="duracion text-violet">
                {duration > 0 && (
                  <>
                    <span className="fw-bold">Duración: </span>{" "}
                    <span>{duration} minutos</span>
                  </>
                )}
              </div>
              <span className="btn-seecontent">
                Ver contenido
              </span>
            </Col>
          </div>
        </div>
      </a>
    </div>
  );
}

export default Result;
