import Carousel from "react-bootstrap/Carousel";
import programa from "../images/carousel/programa1260.png";
import adolescencia from "../images/carousel/adolescencia1260.png";
import pilares from "../images/carousel/4pilares1260.png";
import "../stylesheets/carousel.scss";

function HomeCarousel() {
  return (
    <Carousel>
      {/*
      <Carousel.Item>
        <a href="/restaurante/104" target="_blank" rel="noopener noreferrer">
          <img src={programa} alt="Imagen adolescencia" />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a href="/restaurante/104" target="_blank" rel="noopener noreferrer">
          <img src={adolescencia} alt="Imagen adolescencia" />
        </a>
      </Carousel.Item>
      */
      <Carousel.Item>
        <a href="/restaurante/104" target="_blank" rel="noopener noreferrer">
          <img src={pilares} alt="Imagen 4 Pilares" />
        </a>
      </Carousel.Item>}
    </Carousel>
  );
}

export default HomeCarousel;
