import React, { useEffect, useState } from "react";
import "../stylesheets/variables.scss";
import "../stylesheets/restaurant.scss";
import Result from "../components/Result";
import Pagination from "../components/Pagination";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ArrowRight from "@mui/icons-material/ArrowForwardIos";
import logo_moc from "../images/restaurantes/logo_moc_breadcrumb.png";
import entrante from "../images/restaurantes/img-entrante2.png";
import principal from "../images/restaurantes/img-principal2.png";
import postre from "../images/restaurantes/img-postre2.png";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { BASE_URL, BACKEND_URL, CONTENT_VIEWED_URL } from "../constants/api-urls.js";
import Spinner from "../components/Spinner";
import infografia from "../images/sugerencias/infografia.png";
import recomendacion from "../images/sugerencias/recomendacion.png";
import casoclinic from "../images/sugerencias/casoclinic.png";
import casoclinic2 from "../images/sugerencias/casoclinic2.png";
import pildora from "../images/sugerencias/pildora.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

export const Restaurant = () => {
  const params = useParams();
  const { id } = params; //Aqui recogemos el valor id del restaurante, que lo mostraremos en distintos lugares.
  const [categoryData, setCategoryData] = useState([]);
  const [digitalEntitiesData, setDigitalEntitiesData] = useState([]);
  const [filteredDigitalEntities, setFilteredDigitalEntities] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [bookmarkedEntities, setBookmarkedEntities] = useState([]);
  const [filters, setFilters] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [recommendations, setRecommendations] = useState([]);
  const [showEntrante, setShowEntrante] = useState(false);
  const [showPrincipal, setShowPrincipal] = useState(false);
  const [showPostre, setShowPostre] = useState(false);
  const [activeLink, setActiveLink] = useState(null);
  const [filteredEntrante, setFilteredEntrante] = useState([]);
  const [filteredPrincipal, setFilteredPrincipal] = useState([]);
  const [filteredPostre, setFilteredPostre] = useState([]);


  const PAGE_SIZE = 10;

  const getLogoUrl = (id) => {
    try {
      return require(`../images/home/restaurante/logo/img-restaurante-logo-${id}.png`);
    } catch (error) {
      console.error("Error loading image:", error);
      return '';
    }
  };
  const getImgCardUrl = (id) => {
    try {
      return require(`../images/home/restaurante/card/img-restaurant-card-${id}.png`);
    } catch (error) {
      console.error("Error loading image:", error);
      return '';
    }
  };

  const handleLinkClick = (link) => {    
    setActiveLink((prev) => (prev === link ? null : link));
    actionFilters(link);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}digital_entities/getCategoryById?category_id=${id}`,
          {
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setCategoryData(data[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchDigitalEntities = async () => {
      try {
        const response_digital_entities = await fetch(
          `${BASE_URL}digital_entities/getResourcesByCategory?value=${id}`,
          {
            credentials: "include",
          }
        );

        if (!response_digital_entities.ok) {
          throw new Error("Network response was not ok");
        }

        const data_digital_entities = await response_digital_entities.json();
        setDigitalEntitiesData(data_digital_entities);
        // Calcula los resultados después de actualizar `digitalEntitiesData`
        const hasEntranteResults = data_digital_entities.some(entity => ["infografía", "píldoras informativas", "recomendación de experto"].includes(entity.subtype.toLowerCase()));
        const hasPrincipalResults = data_digital_entities.some(entity => ["masterclass", "curso", "formación intensiva"].includes(entity.subtype.toLowerCase()));
        const hasPostreResults = data_digital_entities.some(entity => ["caso clínico interactivo"].includes(entity.subtype.toLowerCase()));

        setShowEntrante(hasEntranteResults);
        setShowPrincipal(hasPrincipalResults);
        setShowPostre(hasPostreResults);
        
        // Cargar los filtros una vez al montar el componente
        setFilteredEntrante(data_digital_entities.filter(entity =>
          ["infografía", "píldoras informativas", "recomendación de experto"].includes(entity.subtype.toLowerCase())
        ));

        setFilteredPrincipal(data_digital_entities.filter(entity =>
          ["masterclass", "curso", "formación intensiva"].includes(entity.subtype.toLowerCase())
        ));

        setFilteredPostre(data_digital_entities.filter(entity =>
          ["caso clínico interactivo"].includes(entity.subtype.toLowerCase())
        ));

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDigitalEntities();
  }, []);

  useEffect(() => {
    if (filters.length > 0) {
      const filteredResults = digitalEntitiesData.filter((filt) =>
        filters.includes(filt.subtype.toLowerCase())
      );
      setFilteredDigitalEntities(filteredResults);
    } else {
      setFilteredDigitalEntities(digitalEntitiesData);
    }
    setCurrentPage(1);
  }, [digitalEntitiesData, filters]);

  useEffect(() => {
    setTotalPages(Math.ceil(filteredDigitalEntities.length / PAGE_SIZE));
  }, [filteredDigitalEntities]);

  useEffect(() => {
    if (filters.length > 0) {
      const filteredResults = digitalEntitiesData.filter((filt) =>
        filters.includes(filt.subtype.toLowerCase())
      );
      setFilteredDigitalEntities(filteredResults);
    } else {
      setFilteredDigitalEntities(digitalEntitiesData);
    }
    setCurrentPage(1);
  }, [digitalEntitiesData, filters]);

  useEffect(() => {
    setTotalPages(Math.ceil(filteredDigitalEntities.length / PAGE_SIZE));
  }, [filteredDigitalEntities]);

  const current_user_id = JSON.parse(localStorage.getItem("user")).id;

  useEffect(() => {
    const fetchDataBookmarks = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}bookmarks/getUserBookmarks?user_id=${current_user_id}`,
          {
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        const userBookmarks = data.map(
          (bookmark) => bookmark.digital_entity_id
        );
        setBookmarkedEntities(userBookmarks);
      } catch (error) {
        console.error("Error fetching bookmarks:", error);
      }
    };

    fetchDataBookmarks();
  }, [current_user_id]);

  useEffect(() => {
    const fetchRecommendations = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}digital_entities/recomendations`,
          {
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const recommendations = await response.json();

        // Establecer las recomendaciones
        setRecommendations(recommendations);
      } catch (error) {
        console.error("Error fetching recommendations:", error);
      }
    };

    fetchRecommendations();
  }, []);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const newStartIndex = (currentPage - 1) * PAGE_SIZE;
    setStartIndex(newStartIndex);
    setEndIndex(
      Math.min(newStartIndex + PAGE_SIZE, filteredDigitalEntities.length)
    );
  }, [currentPage, filteredDigitalEntities]);

  // Función para obtener la clase del badge en el carousel
  const getBadgeClass = (subtype) => {
    switch (subtype) {
      case 'Infografía':
        return 'bg-badge-entrante text-violet';
      case 'MasterClass':
        return 'bg-badge-principal text-lavander';
      case 'Recomendación de experto':
        return 'bg-badge-entrante text-violet';
      case 'Caso Clínico Interactivo':
        return 'bg-badge-postre text-platpostre';
      case 'Píldoras informativas':
        return 'bg-badge-entrante text-violet';
      case 'Casos clínicos':
        return 'bg-badge-postre text-platpostre';
      case 'Curso':
        return 'bg-badge-principal text-lavander';
      default:
        return 'bg-transparent';
    }
  };

  const actionFilters = (filter) => {
    const filterLower = filter.toLowerCase();
    // Si el filtro ya está activo, lo eliminamos (desactivamos)
    if (filters.includes(filterLower)) {
      setFilters(filters.filter((filt) => filt !== filterLower));
    } else {
      // Si no está activo, primero limpiamos todos los filtros y luego agregamos el nuevo
      setFilters([filterLower]);
    }
  };
  

  function handleViewed(id){
    fetch(CONTENT_VIEWED_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({digital_entity_id: id}),
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network error.");
      }
    })
    .catch((err) => {
      console.log("Connection error: ", err);
    });
  }




  return (
    <>
      <div>
        <Helmet>
          <title>{categoryData && categoryData.title}</title>
        </Helmet>
      </div>
      <div className={`toolbar-${id} toolbar-general`}>
        <div className="background_toolbar"></div>
      </div>
      <Container id="restaurant_container">
        <Row className="page-title d-flex flex-column justify-content-center flex-wrap me-3 mb-6">
          <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-6 my-0 pt-1">
            <li className="breadcrumb-item text-white">
              Restaurante
              {/* <a className="">
                <img
                  src={logo_moc}
                  className="img-moc-breadcrumb"
                  alt="Imagen MOC"
                />
              </a> */}
            </li>
            <li className="text-white opacity-85 mx-3">
              <i className="arrow-breadcrumb">
                <ArrowRight></ArrowRight>
              </i>
            </li>
            {/* <li className="breadcrumb-item breadcrumb-title text-white opacity-85 mt-6">
              {categoryData && categoryData.title}
            </li> */}
          </ul>
        </Row>
        <Row className="card">
          <div className="card-presentation">
            <Col
              sm={2}
              className="col-lg-2 d-flex justify-content-around align-items-center"
            >
              <img
                src={getImgCardUrl(categoryData.id)}
                className="img-restaurant img-fluid w-200px flex-end"
                alt={`Logo ${categoryData && categoryData.title}`}
              />
            </Col>
            <Col sm={10} className="col-lg-10 px-10">
              <div className="logo-title-card-restaurant">
                <img src={getLogoUrl(categoryData.id)} width="120px;" alt="Logo manejo clínico de la obesidad"></img>
                <div className="title-tarjeta text-violet mb-2">
                  {categoryData && categoryData.title}
                </div>
              </div>
              <div
                className="category-description text-violet"
                data-v-9bf084bd=""
              >
                {categoryData && categoryData.description}
              </div>
            </Col>
          </div>
        </Row>
        <Row className="card mb-6">
          <div className="restaurant-content">
            <div className="menudia-description">
              <div className="card-title section-title text-violet mb-4">
                Menú del día
              </div>
              <div className="category-description text-violet">
                <p>
                  Para facilitar un aprendizaje integral y adaptado a las necesidades de cada profesional, hemos clasificado los cursos de nuestro restaurante en tres categorías principales: <b>Entrante</b>, <b>Plato Principal</b> y <b>Postre</b>.
                </p>
                <p>
                  Cada sección está diseñada para proporcionar conocimientos esenciales, prácticas basadas en evidencia y las últimas innovaciones en el tratamiento de la obesidad, asegurando que los profesionales de la salud estén equipados con las herramientas necesarias para abordar esta epidemia de manera efectiva.
                </p>
              </div>
            </div>

            <div className="row gap-10 justify-content-center mb-10">
              {showEntrante && (
                <Col sm={4} className="card-entrante col-lg p-0">
                  <div className="d-flex">
                    <Col sm={6}>
                      <h3 className="title-menudia text-violet">Entrante</h3>
                      <img
                        src={entrante}
                        className="img-menus"
                        alt="Imagen entrante"
                      />
                    </Col>
                    <Col sm={6} className="anchor">
                      <div>
                        {filteredEntrante.some(entity => entity.subtype.toLowerCase() === "infografía") && (

                          <a
                          className={`badge ${activeLink === "Infografías" ? "bg-badge-entrante text-violet" : "bg-light text-dark"}`}
                            onClick={() => handleLinkClick("Infografías")}
                          >
                              Ver Infografías
                          </a>
                        )}

                        {filteredEntrante.some(entity => entity.subtype.toLowerCase() === "píldoras informativas") && (
                          <a
                          className={`badge ${activeLink === "Píldoras informativas" ? "bg-badge-entrante text-violet" : "bg-light text-dark"}`}
                            onClick={() => handleLinkClick("Píldoras informativas")}
                          >             
                              Ver Píldoras informativas
                          </a>
                        )}

                        {filteredEntrante.some(entity => entity.subtype.toLowerCase() === "recomendación de experto") && (
                          <a
                          className={`badge ${activeLink === "Recomendación de experto" ? "bg-badge-entrante text-violet" : "bg-light text-dark"}`}
                            onClick={() => handleLinkClick("Recomendación de experto")}
                          >                                          
                              Ver Recomendación de experto
                          </a>
                        )}
                      </div>
                    </Col>
                  </div>
                </Col>
              )}              
              
              {showPrincipal && (
                <Col sm={4} className="card-principal col-lg p-0">
                  <div className="d-flex">
                    <Col sm={6} className="text-end">
                      <h3 className="title-menudia text-lavander">Plato principal</h3>
                      <img
                        src={principal}
                        className="img-menus"
                        alt="Imagen primer plato"
                      />
                    </Col>
                    <Col sm={6} className="anchor">
                      <div>
                        {filteredPrincipal.some(entity => entity.subtype.toLowerCase() === "masterclass") && (
                          <a
                          className={`badge ${activeLink === "MasterClass" ? "bg-badge-entrante text-violet" : "bg-light text-dark"}`}
                            onClick={() => handleLinkClick("MasterClass")}
                          >      
                            Ver MasterClass
                          </a>
                        )}

                        {filteredPrincipal.some(entity => entity.subtype.toLowerCase() === "curso") && (
                          <a
                          className={`badge ${activeLink === "Curso" ? "bg-badge-entrante text-violet" : "bg-light text-dark"}`}
                            onClick={() => handleLinkClick("Curso")}
                          >  
                            Ver Cursos
                          </a>
                        )}

                        {filteredPrincipal.some(entity => entity.subtype.toLowerCase() === "formación intensiva") && (
                          <a
                          className={`badge ${activeLink === "Formación Intensiva" ? "bg-badge-entrante text-violet" : "bg-light text-dark"}`}
                            onClick={() => handleLinkClick("Formación Intensiva")}
                          >
                            Ver Formaciones Intensivas
                          </a>
                        )}
                      </div>
                    </Col>
                  </div>
                </Col>
              )}
              {showPostre && (
                <Col sm={4} className="card-postre col-lg p-0">
                  <div className="d-flex">
                    <Col sm={6} className="text-end">
                      <h3 className="title-menudia text-platpostre">Postre</h3>
                      <img
                        src={postre}
                        className="img-menus"
                        alt="Imagen postre"
                      />
                    </Col>
                    <Col sm={6} className="anchor">
                      <div className="w-100">
                        {filteredPostre.some(entity => entity.subtype.toLowerCase() === "caso clínico interactivo") && (
                          <a
                          className={`badge ${activeLink === "Caso Clínico Interactivo" ? "bg-badge-entrante text-violet" : "bg-light text-dark"}`}
                            onClick={() => handleLinkClick("Caso Clínico Interactivo")}
                          >             
                            Ver Caso Clínicos Interactivos
                          </a>
                        )}
                      </div>
                    </Col>
                  </div>
                </Col>
              )}
              
            </div>
            <div className="mt-11">
              {digitalEntitiesData.length === 0 && (
                <Spinner />
              )}
              {filteredDigitalEntities.slice(startIndex, endIndex).map(entity => {
                return (
                  <Result
                    resource_id={entity.id}
                    parent={entity.parent_id}
                    title={entity.title}
                    thumbnail={entity.thumbnail_url}
                    description={entity.abstract}
                    duration={entity.video_duration}
                    type={entity.type}
                    subtype={entity.subtype}
                    isViewed={entity.viewed}
                    isFavorite={bookmarkedEntities.includes(entity.id)}
                  />
                );
              })}

              <div className="d-flex align-items-center justify-content-center">
                <div className="pagination">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </Row>
        <Row className="card mb-6">
          <div className="restaurant-content">
            <div className="card-title section-title mb-4 text-violet">
              Otros contenidos
            </div>

            {/* Mostrar spinner mientras cargan las recomendaciones */}
            {recommendations.length === 0 ? (
              <Spinner />
            ) : (

              <OwlCarousel
                className="owl-theme"
                loop={false}
                margin={5}
                nav
                dots={false}
                responsive={{
                  0: { items: 1.5 },
                  600: { items: 3 },
                  1000: { items: 4.2 },
                }}
              
              >
                {recommendations.map((item) => (
                  <div key={item.id} className="item card-sugerencias">                               
                    <a href={`/restaurante/${item.parent_id}/plato/${item.id}`}>
                      <div className="p-6">
                        <div className="col">
                          <div className="col d-flex justify-content-around align-items-center mb-3">
                            <img
                              src={`${BACKEND_URL}${item.thumbnail_url}`}
                              className="img-fluid img-sugerencias"
                              alt={item.title}
                            />
                          </div>
                          <div
                            className={`badge bg-transparent ${getBadgeClass(item.subtype)}`}
                          >
                            {item.subtype}
                          </div>
                          <div className="fw-semibold text-tarjeta text-violet truncate-multiline-carousel">
                            {item.title}
                          </div>
                          <div className="btn-seecontent-suggeriment">
                            Ver contenido
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
              </OwlCarousel>
             )}
          </div>
        </Row>
      </Container>
    </>
  );
};
