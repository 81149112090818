import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { RESET_PASSWORD_URL } from "../constants/api-urls";
import "../stylesheets/singin.scss";
import logo_novo_blue from "../images/logo_novo_blue.png";

const successResetPassword = "Se ha recibido la solicitud correctamente";
const failResetPassword =
  "No se ha podido realizar la recuperación de contraseña";

export const PasswordReset = ({ pageTitle }) => {
  const [emailInput, setEmailInput] = useState("");
  const [notice, setNotice] = useState("");

  const handleEmailInputChange = (event) => {
    setEmailInput(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const params = { email: emailInput };

    fetch(RESET_PASSWORD_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
    .then((response) => {
      if (!response.ok) throw new Error("Network error.");
      setNotice(response.ok ? successResetPassword : failResetPassword);
    })
    .catch((err) => {
      console.log("Connection error: ", err);
    });
  };

  return (
    <>
      <div>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
      </div>
      <div className="bg_login"></div>
      <div className="form-container">
        <div className="w-lg-500px form-passwordreset">
          <div>
            {""}
            {/* esto sería un modal */}
            <p className="error-notice">{notice}</p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="text-center mb-10">
              <h1 className="title-login">¿Has olvidado la contraseña?</h1>
              <p className="">
                Introduce tu correo electrónico, y si es un correo
                registrado te enviaremos un enlace para que restablezcas tu
                contraseña.
              </p>
            </div>
            <div className="fv-row mb-8">
              <label className="textfield-login">Correo electrónico</label>
              <input
                type="email"
                className="form-control bg-transparent"
                placeholder="Correo electrónico"                
                onChange={handleEmailInputChange}
                required
              />
            </div>
            <div className="d-flex flex-wrap justify-content-center pb-lg-0 gap-3">
              <button type="submit" className="btn-main">
                Enviar
              </button>
              <a href="/sign-in" className="btn-cancel-password">
                Cancelar
              </a>
            </div>
          </form>
        </div>
      </div>
      <div className="footer_login">
            <img
              src={logo_novo_blue}
              alt="Logo"
              className="img_novonordisk"
            ></img>
        </div>
    </>
  );
};